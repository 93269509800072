<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%;">
      <v-data-table
        v-model="selected"
        hide-default-footer
        :headers="headers"
        :items="getWithRelationText(getSearchResultThesauruses)"
        :loading="loading"
        :items-per-page="query.pageSize"
        loading-text="Loading... Please wait"
        show-select
        :footer-props="{
          itemsPerPageText: 'صفوف لكل صفحة',
          pageText: ` من ` + '1000' + `  صفحات`,
          itemsPerPageOptions: [5, 10, 15, 20, 50, 75],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              style="width: 100%; max-width: 450px"
              v-model="searchTerm"
              append-icon="search"
              label=""
              :disabled="loading"
              single-line
              clearable
              hide-details
              @click:clear="viewAll"
              @click:append="() => onChange(searchTerm)"
              ref="searchThesaurus"
              @change="(term) => onChange(term)"
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-select
              :disabled="loading"
              @input="updateSortAndOrder"
              style="height: 40px; min-height: 44px; max-width: 105px;"
              dense
              label="الترتيب حسب"
              v-model="sortByDropdown"
              :items="sortDropdown"
              filled
              class="ml-4"
              hide-selected
              return-object
              outlined
            ></v-select>
            <v-select
              :disabled="loading"
              @input="updateSortAndOrder"
              style="height: 40px;min-height: 44px;max-width: 105px;"
              dense
              class="ml-4"
              label="التسلسل"
              v-model="orderDropdown"
              :items="[
                { text: 'تصاعدي', field: 'ASC' },
                { text: 'تنازلي', field: 'DESC' },
              ]"
              filled
              hide-selected
              return-object
              outlined
            ></v-select>
            <v-btn
              v-if="selected.length !== 0"
              class="ml-3"
              depressed
              outlined
              large
              :loading="loading"
              v-show="!loading"
              @click="deleteMultiple"
            >
              <v-icon>
                delete
              </v-icon>
              <span style="font-size: 1.2rem" class="mr-1">
                حذف
              </span>
            </v-btn>
            <v-btn
              depressed
              outlined
              large
              :loading="loading"
              @click="goToCreate"
            >
              <v-icon class="ml-1">
                mdi-file-upload-outline
              </v-icon>
              <span style="font-size: 1.2rem">
                جديد
              </span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="handleClick(item)">
            mdi-archive-edit
          </v-icon>
        </template>
      </v-data-table>
      <div class="d-flex justify-content-center w-100 mt-4">
        <v-pagination
          v-model="currentPage"
          color="#f29100ff"
          :length="totalPages"
          :total-visible="5"
          @input="handlePageChange"
        >
        </v-pagination>
        <v-select
          :items="[5, 10, 15, 20, 50, 75]"
          v-model="query.pageSize"
          dense
          flat
          :loading="loading"
          label="عدد"
          class="mr-2"
          @change="updateSortAndOrder"
          style="max-width: 55px; max-height: 50px;"
        ></v-select>
      </div>
      <div v-if="!loading" class="d-flex justify-content-center mt-4">
        {{ currentPageCountStart }} -
        {{ finalNumberText }}
        من أصل {{ getThesaurusesGlobalCount }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import thesaurusConfig from "@helpers/thesaurusStructure";
import getSortableField from "@helpers/getSortableField";

export default {
  data() {
    return {
      title: thesaurusConfig.title,
      singleSelect: false,
      searchTerm: "",
      selected: [],
      loading: false,
      sortByDropdown: { text: "#UID", field: "_id" },
      orderDropdown: { text: "تصاعدي", field: "ASC" },
      headers: [
        ...thesaurusConfig.getHeader(),
        { text: "", value: "actions", sortable: false },
      ],
      sortDropdown: thesaurusConfig.getSortDropdown(),
      query: {
        order: "ASC",
        sortBy: "id",
        pageSize: 5,
        pageNumber: 1,
      },
      currentPageCountStart: 1,
      currentPageCountEnd: 1,
      currentPage: 1,
      totalPages: 0,
    };
  },
  methods: {
    ...mapActions("thesauruses", [
      "fetchSearchResultThesauruses",
      "fetchAllThesauruses",
      "deleteThesaurus",
    ]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    ...mapMutations("thesauruses", ["SET_NUMBER_OF_ROWS"]),
    setLoading(status) {
      this.loading = status;
      this.updatePagination();
    },
    viewAll() {
      this.setQuery();
      this.fetchAllThesauruses({
        query: this.query,
        loadingFunction: this.setLoading,
      });
      this.setLoading(true);
      this.searchTerm = "";
    },
    notify(message) {
      this.notifyVuex({ show: true, message });
    },
    updatePagination() {
      this.totalPages = Math.ceil(
        this.getThesaurusesGlobalCount / this.query.pageSize
      );
      this.currentPageCountStart =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        1;
      this.currentPageCountEnd =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        parseInt(this.query.pageSize);
    },
    search(term) {
      this.setQuery();
      if (term) {
        this.fetchSearchResultThesauruses({
          term,
          query: { ...this.query },
          loadingFunction: this.setLoading,
        });
      } else {
        this.viewAll();
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.query.pageNumber = value;

      this.updateSortAndOrder();
      window.scrollTo(0, 0);
    },
    deleteMultiple() {
      let str = "";
      this.selected.forEach((el) => {
        str += el.name;
        str += el.id;
      });
      this.$confirm({
        message: str,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            const ids = [];
            this.selected.forEach((el) => {
              ids.push(el.id);
            });
            this.deleteThesaurus({
              ids,
              loadingFunction: this.setLoading,
              inCaseOfError: this.notify,
            });
            this.selected = [];
          }
        },
      });
    },
    handleClick(row) {
      if (!this.loading) {
        this.$router.push({ name: "ThesaurusEdit", params: { id: row.id } });
      }
    },
    goToCreate() {
      this.$router.push({ name: "ThesaurusCreate" });
    },
    onChange(term) {
      this.search(term);
      this.$refs.searchThesaurus.blur();
    },
    setQuery() {
      this.query.sortBy = getSortableField(this.sortByDropdown.field);
      this.query.order = this.orderDropdown.field;
    },
    updateSortAndOrder() {
      this.SET_NUMBER_OF_ROWS(this.query.pageSize);
      if (this.getSearchTermThesaurus) {
        this.search(this.searchTerm);
      } else {
        this.viewAll();
      }
    },
    convertRelationToString(num) {
      switch (num) {
        case 0:
          return "Unknown";
        case 1:
          return "أساسي";
        case 2:
          return "مواز";
        case 3:
          return "تابع ";
        default:
          return "Unknown";
      }
    },
    getWithRelationText(arr) {
      let newArr = [];
      arr.forEach((el) => {
        const { relation, ...obj } = el;
        newArr.push({
          ...obj,
          relation: this.convertRelationToString(relation),
        });
      });
      return newArr;
    },
  },
  computed: {
    ...mapGetters("thesauruses", [
      "getSearchResultThesauruses",
      "getSearchTermThesaurus",
      "getThesaurusesGlobalCount",
      "getThesaurusesLocalCount",
      "getNumberOfRows",
    ]),
    finalNumberText: function() {
      if (this.currentPageCountEnd > this.getThesaurusesGlobalCount) {
        return this.getThesaurusesGlobalCount;
      } else {
        return this.currentPageCountEnd;
      }
    },
  },
  mounted() {
    this.query.pageSize = this.getNumberOfRows;
    this.currentPage = 1;
    if (!this.getSearchTermThesaurus) {
      this.viewAll();
    }
    this.searchTerm = this.getSearchTermThesaurus;
  },
};
</script>

<style>
th.text-right > span {
  font-size: 19px;
}

td.text-right {
  font-size: 16px !important;
}

.v-label {
  margin-top: 6px;
}

.v-select__slot {
  height: 44px;
}

div.v-select__slot:nth-child(1) {
  max-height: 35px;
  flex-direction: row-reverse;
}

div.v-select__selections:nth-child(1) {
  max-height: 30px;
}

div.v-select__slot:nth-child(1) > div:nth-child(2) {
  align-self: center;
}

div.v-select__slot:nth-child(1) > label:nth-child(1) {
  right: 8px !important;
}
</style>
