<template>
  <div class="d-flex justify-center mb-6">
    <div
      v-if="wholePageLoad"
      class="d-flex h-100 justify-center align-items-center"
      style="min-height:50vh;"
    >
      <v-progress-circular
        indeterminate
        color="black"
        size="50"
      ></v-progress-circular>
    </div>
    <v-card v-else elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-autocomplete
          chips
          v-model="selectedEntityOne"
          :items="entitiesOne"
          :loading="isLoading"
          :search-input.sync="entitySearchOne"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Entity 1"
          class="mb-6"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.type"> </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="selectedEntityTwo"
          chips
          :items="entitiesTwo"
          :loading="isLoading"
          :search-input.sync="entitySearchTwo"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Entity 2"
          class="mb-6"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.type"> </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-select
          :items="[
            { relationText: 'Unknown', number: 0 },
            { relationText: 'أساسي', number: 1 },
            { relationText: 'مواز', number: 2 },
            { relationText: 'تابع', number: 3 },
          ]"
          dense
          v-model="fields.relation"
          item-text="relationText"
          item-value="number"
          label="علاقة 2 إلى 1"
          class="pt-5"
        ></v-select>

        <v-btn class="mr-4" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { searchEntities, getEntityById } from "@services/Entities.api";
export default {
  data: () => ({
    fields: { relation: null, entityId1: 0, entityId2: 0 },
    valid: true,
    isLoading: false,

    entitiesOne: [],
    entitiesTwo: [],
    selectedEntityOne: null,
    selectedEntityTwo: null,
    entitySearchOne: null,
    entitySearchTwo: null,

    wholePageLoad: true,
  }),

  methods: {
    ...mapActions("thesauruses", ["updateThesaurus", "getThesaurus"]),
    submit() {
      const { id } = this.$route.params;

      this.fields.entityId1 = this.selectedEntityOne.id
        ? this.selectedEntityOne.id
        : this.selectedEntityOne;

      this.fields.entityId2 = this.selectedEntityTwo.id
        ? this.selectedEntityTwo.id
        : this.selectedEntityTwo;

      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.wholePageLoad = true;

            this.updateThesaurus({ id, ...this.fields }).then(() => {
              // this.$router.push({ name: "BackendThesaurus" });
              this.wholePageLoad = false;
            });
          }
        },
      });
    },
    goBack() {
      this.$router.push({ name: "BackendThesaurus" });
    },
  },
  mounted() {
    const { id } = this.$route.params;

    this.getThesaurus(id).then(({ entityId1, entityId2, relation }) => {
      this.fields.relation = relation;

      getEntityById(entityId1).then((res) => {
        this.selectedEntityOne = res.data.payload;
        this.entitiesOne.push({ ...res.data.payload });
      });

      getEntityById(entityId2).then((res) => {
        this.selectedEntityTwo = res.data.payload;
        this.entitiesTwo.push({ ...res.data.payload });
        this.wholePageLoad = false;
      });
    });
  },
  watch: {
    entitySearchOne(val) {
      if (!val) return;

      this.isLoading = true;

      searchEntities(val, {})
        .then((res) => {
          this.entitiesOne = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    entitySearchTwo(val) {
      if (!val) return;

      this.isLoading = true;

      searchEntities(val, {})
        .then((res) => {
          this.entitiesTwo = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
