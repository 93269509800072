export default {
  title: "المكانز",
  entityName: "thesaurus",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "الأول #UID",
      value: "entityId1",
      align: "right",
      field: "_entity_id_1",
      sortable: false,
    },
    {
      text: "التسمية الأولى",
      value: "entity1Name",
      align: "right",
      field: "_entity1Name",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "مكنز التسمية الأولى",
      value: "entity1Type",
      align: "right",
      field: "_entity1Type",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "الثاني #UID",
      value: "entityId2",
      align: "right",
      field: "_entity_id_2",
      sortable: false,
    },
    {
      text: "التسمية الثانية",
      value: "entity2Name",
      align: "right",
      field: "_entity2Name",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "مكنز التسمية الثانية",
      value: "entity2Type",
      align: "right",
      field: "_entity2Type",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "علاقة الثاني بالأولى",
      value: "relation",
      align: "right",
      field: "_relation",
      sortable: false,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field, restrictSort }) => {
      if (!restrictSort) {
        newArr.push({ text, field });
      }
    });

    return newArr;
  },
};
