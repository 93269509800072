<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-autocomplete
          v-model="modelOne"
          :items="itemsOne"
          :loading="isLoading"
          :search-input.sync="searchOne"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Entity 1"
          class="mb-5"
        >
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-text v-bind="attr" :input-value="selected" v-on="on">
              <span v-text="item.name"></span>
            </v-text>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.type"> </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="modelTwo"
          :items="itemsTwo"
          :loading="isLoading"
          :search-input.sync="searchTwo"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Entity 2"
          class="mb-5"
        >
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-text v-bind="attr" :input-value="selected" v-on="on">
              <span v-text="item.name"></span>
            </v-text>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.type"> </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-select
          :items="[
            { relationText: 'Unknown', number: 0 },
            { relationText: 'Parent', number: 1 },
            { relationText: 'Sibling', number: 2 },
            { relationText: 'Child', number: 3 },
          ]"
          dense
          v-model="fields.relation"
          item-text="relationText"
          item-value="number"
          label="علاقة 2 إلى 1"
          class="pt-5"
        ></v-select>

        <v-btn class="mr-4" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { searchEntities } from "@services/Entities.api";
export default {
  data: () => ({
    fields: { relation: null, entityId1: 0, entityId2: 0 },
    valid: true,
    isLoading: false,
    itemsOne: [],
    itemsTwo: [],
    modelOne: null,
    modelTwo: null,
    searchOne: null,
    searchTwo: null,
  }),

  methods: {
    ...mapActions("thesauruses", ["addThesaurus"]),
    submit() {
      this.fields.entityId1 = this.modelOne;
      this.fields.entityId2 = this.modelTwo;
      this.addThesaurus(JSON.stringify(this.fields)).then(() => {
        this.$router.push({ name: "BackendThesaurus" });
      });
    },
    goBack() {
      this.$router.push({ name: "BackendThesaurus" });
    },
  },
  watch: {
    searchOne(val) {
      if (!val) return;

      this.isLoading = true;

      searchEntities(val, {})
        .then((res) => {
          this.itemsOne = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchTwo(val) {
      if (!val) return;

      this.isLoading = true;

      searchEntities(val, {})
        .then((res) => {
          this.itemsTwo = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
